#Footer {
  width: 100vw;
  min-height: 500px;
  color: whitesmoke;
  background-color: #182e4d;
}

#copy-right {
  width: 100vw;
  color: whitesmoke;
  background-color: #182e4d;
}

#site-map {
  background-color: #1e3960;
  border-radius: 10px;
}
