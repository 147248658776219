body {
  font-family: "Montserrat", sans-serif;
}

a {
  color: whitesmoke;
}

li > a {
  color: blue;
}

.redText {
  color: #ee2e24;
}

.blueText {
  color: #177db9;
}

.greenText {
  color: #2fa585;
}

.yellowText {
  color: #ffaa1a;
}
