#orange {
  background-image: url(../../images/blueOrangeBanner.png);
  background-size: 100%;
  height: 10vh;
}

#red {
  background-image: url(../../images/blueRedBanner.png);
  background-size: 100%;
  height: 10vh;
}
